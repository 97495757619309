module.exports = [{
      plugin: require('../node_modules/gatsby-theme-q3-mui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"stripQueryString":true,"siteUrl":"https://journalengine.com/"},
    },{
      plugin: require('../node_modules/gatsby-theme-q3/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
